
import {Component, Vue} from 'vue-property-decorator';
import {Form} from 'element-ui';
import Pagination from '@/components/Pagination/index.vue';
import {
  applications,
  applicationRequests,
  applicationCreateRequests,
  roles,
  roleMappings,
  users,
} from '../../resources';
import moment from 'moment';
import {Application, ApplicationQuota} from '@/externals/MaxCI-Application-v1';
import {getUserId, uploadImg} from '../../api/publicMethod';
import {ObjectId} from 'bson';
import lodash from 'lodash';
import {RoleMapping} from '@/externals/MaxCI-RoleMapping-v1';

interface ManagerList extends RoleMapping {
  roleName: string;
  userName: string;
  phone: string;
}
@Component({
  name: 'adminApplicationList',
  components: {
    Pagination,
  },
})
export default class extends Vue {
  private operateRoleData: null | ManagerList = null;
  private dialogRoleDel = false;
  private managerList = [] as Array<ManagerList>;
  private operateId: ObjectId | null = null;
  private dialogManager = false;
  private quotaForm: ApplicationQuota = {
    availableServices: [],
    availableChannels: [],
    channelCount: -1,
    mallShopCount: -1,
  };
  private quotaRules = {
    channelCount: [
      {required: true, message: '请输入渠道数目', trigger: 'blur'},
    ],
    mallShopCount: [
      {required: true, message: '请输入商城店铺数量', trigger: 'blur'},
    ],
  };
  private dialogFeatures = false; //设置功能弹窗
  private src = '';
  private action = '';
  private imgData = {};
  private formData = {
    name: '',
    logo: '',
    description: '',
    mallService: '',
    agencyService: '',
    IDPhotoService: '',
  };
  private rules = {
    name: [{required: true, message: '请输入应用名称', trigger: 'blur'}],
    logo: [{required: true, message: '请上传应用图标', trigger: 'blur'}],
  };
  private dialogAdd = false;
  private searchData = {name: ''};
  private dialogDel = false;
  private operateData: null | Application = null;
  private total = 0;
  private list: Array<Application> = [];
  private listLoading = true;
  private listParams = {
    page: 1,
    limit: 10,
  };
  private submitFlag = false; //提交开关
  private userId: ObjectId = getUserId();
  private applicationId = ObjectId.createFromHexString(
    this.$route.params.applicationId,
  );

  async created() {
    this.$store.state.applicationId = this.$route.params.applicationId;
    this.updateList();
  }
  private async repeatBtn() {
    // const list = await productLabels.find(stage =>
    //   stage
    //     .$group(
    //       e =>
    //         e.$object(object =>
    //           object('applicationId', e =>
    //             e.$fieldPath(f => f('spec')('applicationId')),
    //           )('shopId', e => e.$fieldPath(f => f('spec')('shopId')))(
    //             'name',
    //             e => e.$fieldPath(f => f('spec')('name')),
    //           ),
    //         ),
    //       group =>
    //         group('productLabelId', e =>
    //           e.$first(p => p.$fieldPath(f => f('_id'))),
    //         )('count', e => e.$sum(p => p.$literal(1)))('values', e =>
    //           e.$addToSet(a => a.$fieldPath(f => f('spec')('values'))),
    //         ),
    //     )
    //     .$match(match =>
    //       match(
    //         f => f('count'),
    //         e => e.$gt(1),
    //       ),
    //     ),
    // );
    // console.log(list);
    // for (let item of list) {
    //   await productLabels.delete(filter =>
    //     filter(
    //       f => f('_id'),
    //       e => e.$ne(item.productLabelId),
    //     )(
    //       f => f('spec')('name'),
    //       e => e.$eq(item._id.name),
    //     )(
    //       f => f('spec')('shopId'),
    //       e => e.$eq(item._id.shopId!),
    //     )(
    //       f => f('spec')('applicationId'),
    //       e => e.$eq(item._id.applicationId),
    //     ),
    //   );
    //   await productLabels.update(
    //     filter =>
    //       filter(
    //         f => f('_id'),
    //         e => e.$eq(item.productLabelId),
    //       ),
    //     update =>
    //       update.$set(s =>
    //         s(
    //           f => f('spec')('values'),
    //           [
    //             ...item.values.reduce((p, v) => {
    //               for (let o of v) {
    //                 p.add(o);
    //               }
    //               return p;
    //             }, new Set<string>()),
    //           ],
    //         ),
    //       ),
    //   );
    // }
    // const sortList = await productLabels.find(stage =>
    //   stage
    //     .$match(match =>
    //       match(
    //         f => f('spec')('name'),
    //         e => e.$eq('分类'),
    //       ),
    //     )
    //     .$unwind(f => f('spec')('values'), undefined, false)
    //     .$group(
    //       e =>
    //         e.$object(object =>
    //           object('applicationId', e =>
    //             e.$fieldPath(f => f('spec')('applicationId')),
    //           )('shopId', e => e.$fieldPath(f => f('spec')('shopId')))(
    //             'value',
    //             e => e.$fieldPath(f => f('spec')('values')),
    //           ),
    //         ),
    //       group =>
    //         group('productLabelId', e =>
    //           e.$first(p => p.$fieldPath(f => f('_id'))),
    //         )('count', e => e.$sum(p => p.$literal(1))),
    //     )
    //     .$match(match =>
    //       match(
    //         f => f('count'),
    //         e => e.$gt(1),
    //       ),
    //     ),
    // );
    // for (let item of sortList) {
    //   await productLabels.update(
    //     filter =>
    //       filter(
    //         f => f('_id'),
    //         e => e.$eq(item.productLabelId),
    //       ),
    //     update =>
    //       update.$set(s =>
    //         s(
    //           f => f('spec')('values'),
    //           [
    //             ...item.values.reduce((p, v) => {
    //               for (let o of v) {
    //                 p.add(o);
    //               }
    //               return p;
    //             }, new Set<string>()),
    //           ],
    //         ),
    //       ),
    //   );
    // }
  }
  //平台配置信息
  get platformConfig() {
    return this.$store.state.pConfig;
  }
  //删除应用负责人保存
  private async delRoleSumbit() {
    const operateRoleData = this.operateRoleData;
    if (operateRoleData) {
      const roleMapping = await roleMappings.delete(filter =>
        filter(
          f => f('_id'),
          e => e.$eq(operateRoleData._id),
        )(
          f => f('spec')('applicationId'),
          e => e.$eq(this.applicationId),
        ),
      );
      if (roleMapping) {
        this.dialogRoleDel = false;
        this.$message.success('已删除');
        this.managerPopUps(this.operateId);
      }
    }
  }
  //删除应用负责人
  private async delRolePopUps(row: ManagerList) {
    this.dialogRoleDel = true;
    this.operateRoleData = row;
  }
  //查询应用负责人
  private async managerPopUps(id: ObjectId | null) {
    this.dialogManager = true;
    this.operateId = id;
    if (this.operateId) {
      const role = await roles.find(stage => stage);
      const list = await roleMappings.find(stage =>
        stage.$match(match =>
          match(
            f => f('spec')('applicationId'),
            e => e.$eq(this.applicationId),
          )(
            f => f('spec')('scopeId'),
            e => e.$eq(this.operateId as ObjectId),
          ),
        ),
      );
      let userIdData: Array<ObjectId> = [];
      list.forEach(item => {
        userIdData.push(item.spec.userId);
      });
      userIdData = Array.from(new Set(userIdData));
      const userData = await users.find(stage =>
        stage.$match(match =>
          match(
            f => f('_id'),
            e => e.$in(userIdData),
          )(
            f => f('spec')('applicationId'),
            e => e.$eq(this.applicationId),
          ),
        ),
      );
      const userInfoData = lodash.zipObject(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        userData.map(v => v._id.toHexString()) as any,
        userData,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ) as any;
      this.managerList = list.map(v => {
        let roleName = '';
        for (let item of role) {
          if (v.spec.roleId.equals(item._id)) {
            roleName = item.spec.name;
          }
        }
        return {
          ...v,
          roleName: roleName,
          userName: userInfoData[v.spec.userId.toHexString()]
            ? userInfoData[v.spec.userId.toHexString()].spec.name
            : '',
          phone: userInfoData[v.spec.userId.toHexString()]
            ? userInfoData[v.spec.userId.toHexString()].spec.phone
            : '',
        };
      });
    }
  }
  //设置功能弹窗
  private featuresPopUps(data: Application) {
    this.operateData = data;
    this.dialogFeatures = true;
    this.quotaForm = data.spec.quota;
  }
  //设置功能保存
  private async featuresSumbit() {
    (this.$refs.quotaForm as Form).validate(async valid => {
      if (valid) {
        try {
          const application = await applications.update(
            filter =>
              filter(
                f => f('_id'),
                e => e.$eq(this.operateData?._id as ObjectId),
              ),
            update =>
              update.$set(s => s(f => f('spec')('quota'), this.quotaForm)),
          );
          if (application.length > 0) {
            applicationRequests
              .create(
                [
                  {
                    spec: {
                      type: '更新应用',
                      applicationId: this.operateData?._id as ObjectId,
                      enableServices: this.quotaForm.availableServices,
                    },
                  },
                ],
                {
                  watch: {
                    filter: filter =>
                      filter(
                        f => f('operationType'),
                        e => e.$eq('update'),
                      )(
                        f => f('fullDocument')('status')('phase'),
                        e => e.$exists(true),
                      ),
                  },
                  fullResource: true,
                },
              )
              .then(res => {
                if (res[0].status?.phase === '成功') {
                  this.$message.success('修改成功');
                  this.dialogFeatures = false;
                  this.updateList();
                } else {
                  this.$message.error(
                    res[0].status?.conditions[0].message ?? '',
                  );
                }
              });
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          this.$message.error('网络异常，请稍后重试');
        } finally {
          this.submitFlag = false;
        }
      } else {
        return false;
      }
    });
  }
  //拼接图片
  get image() {
    let imageUrl = '';
    if (this.formData.logo) {
      imageUrl = this.platformConfig?.downloadUrl + this.formData.logo;
    }
    return imageUrl;
  }
  //图片上传成功
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private successAvatar(response: any) {
    this.formData.logo = response.file;
  }
  //获取图片上传地址
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async beforeAvatarUpload(file: any) {
    const isJPEG = file.type === 'image/jpeg';
    const isPNG = file.type === 'image/png';
    const isPG = isJPEG || isPNG; //限制图片格式为jpg / png
    if (!isPG) {
      this.$message.error('上传图片只能是 JPG 或 PNG 格式!');
      throw new Error();
    } else {
      if (this.platformConfig) {
        const res = await uploadImg(
          this.platformConfig.channels[0].channelId,
          this.platformConfig.applicationId,
          false,
          [
            {
              type: '缩放',
              mode: 'fill',
              width: 200,
              height: 200,
            },
          ],
        );
        if (!res) {
          throw new Error();
        }
        this.action = res.status?.upload?.url ?? '';
        this.imgData = res.status?.upload?.data ?? {};
      } else {
        throw new Error();
      }
    }
  }
  // 添加保存
  private addSumbit() {
    (this.$refs.formData as Form).validate(valid => {
      if (valid) {
        try {
          this.submitFlag = true;
          const data = {
            userId: this.userId,
            name: this.formData.name,
            logo: this.formData.logo,
            description: this.formData.description,
            service: '商城' as '商城' | '配送' | '跑腿' | '二手',
          };
          applicationCreateRequests
            .create(
              [
                {
                  spec: data,
                },
              ],
              {
                watch: {
                  filter: filter =>
                    filter(
                      f => f('operationType'),
                      e => e.$eq('update'),
                    )(
                      f => f('fullDocument')('status')('phase'),
                      e => e.$exists(true),
                    ),
                },
                fullResource: true,
              },
            )
            .then(res => {
              if (res[0].status?.phase === '成功') {
                this.$message.success('保存成功');
                this.dialogAdd = false;
                this.updateList();
              } else {
                this.$message.error(res[0].status?.conditions[0].message ?? '');
              }
            });
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          this.$message.error('网络异常，请稍后重试');
        } finally {
          this.submitFlag = false;
        }
      } else {
        return false;
      }
    });
  }
  // 添加弹窗
  private addPopUps() {
    this.dialogAdd = true;
  }
  //转换时间
  getTime(time: string) {
    return moment(time).format('YYYY-MM-DD HH:mm:ss');
  }
  //搜索数据
  private checkList() {
    this.listParams.page = 1;
    this.updateList();
  }
  private async updateList() {
    this.listLoading = true;
    try {
      const list = await applications.find(stage =>
        stage
          .$match(match => {
            if (this.searchData.name) {
              match(
                f => f('spec')('name'),
                e => e.$regex(new RegExp(this.searchData.name)),
              );
            } else {
              match(
                f => f('spec')('name'),
                e => e.$ne('平台应用'),
              );
            }
            match(
              f => f('status')('phase'),
              e => e.$eq('就绪'),
            );
            return match;
          })
          .$facet(facet =>
            facet('table', tableStage =>
              tableStage
                .$sort(sort =>
                  sort(f => f('metadata')('creationTimestamp'), '降序'),
                )
                .$skip((this.listParams.page - 1) * this.listParams.limit)
                .$limit(this.listParams.limit),
            )('count', countStage => countStage.$count('count')),
          ),
      );
      this.total = list[0].count[0] ? list[0].count[0].count.valueOf() : 0;
      this.list = list[0].table;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.listLoading = false;
    }
  }
}
